<style src="./PatientGiftCard.scss" lang="scss" scoped></style>
<template>
  <section
    class="patient-gift-card"
    :class="{ modalOpen: showModalQuotaInformation || showModalAcceptTerm }"
  >
    <!-- HEADER -->
    <PatientHeader />
    <!-- CONTENT -->

    <section class="title">
      <span
        :style="{ 'text-decoration': 'underline', cursor: 'pointer' }"
        @click="onClickBack"
      >
        Início
      </span>
      <IconArrow />
      <span>Gift Card</span>
    </section>

    <section class="content-form">
      <section class="content card">
        <div class="gift-card-card">
          <div class="gift-card-container">
            <img class="logo" :src="appLogo" alt="Logo Medicar" />
            <div class="gift-card-info">
              <span class="gift-card-title">Código do Gift Card</span>
              <span class="gift-card-code">{{ rescuedGiftCard }}</span>
            </div>
          </div>
          <div
            class="gift-card-image"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class="icon-gift-card"
              :src="giftCardSvg"
              alt="Gift Card"
            />
          </div>
        </div>
        <div class="balance-card">
          <div class="balance-flex">
            <div class="balance-container">
              <span class="balance-title">Cotas Disponíveis</span>
              <img
                v-if="showBalance"
                :src="require(`@/assets/eye_disabled.svg`)"
                alt="Gift Card"
                title="Ocultar cotas"
                class="btn-view"
                @click="toggleBalance()"
              />
              <img
                v-if="!showBalance"
                :src="require(`@/assets/eye.svg`)"
                alt="Gift Card"
                title="Exibir cotas"
                class="btn-view"
                @click="toggleBalance()"
              />
            </div>

            <div v-show="showBalance" class="quotas">
              <div
                class="content-quotas"
                v-for="item in cotes"
                v-bind:key="item.esp_nome"
              >
                <span style="color: white">{{ item.esp_nome }}</span>
                <span style="color: white"
                  >{{ item.quantidade_total }} consultas</span
                >
              </div>
            </div>

            <div class="details-text">
              <span @click="openModalQuotaInformation">Ver mais detalhes</span>
            </div>
          </div>
        </div>
      </section>

      <section class="content">
        <div class="include-gift-card-container">
          <div class="gift-card-form">
            <label class="gift-card-label-code" for="gift-card-input"
              >Insira o código de utilização do Gift Card</label
            >
            <input
              class="gift-card-input"
              id="gift-card-input"
              type="text"
              maxlength="12"
              v-model="giftCard"
              @keyup.enter="openModalAcceptTerm"
            />
            <PlButton
              label="Resgatar"
              class="pl-btn redeem"
              @click="sendGiftCard()"
            />
            <span class="gift-card-span-terms" @click="openModalAcceptTerm">
              Termos e Condições de Uso
            </span>

            <div id="accept-term-modal" v-if="showModalAcceptTerm">
              <div class="modal-header">
                <h2>Termo de Consentimento</h2>
                <i
                  class="fas fa-times fa-2x btn-close"
                  @click="closeModalAcceptTerm()"
                ></i>
              </div>
              <div class="modal-dialog">
                <component :is="termComponent" />
              </div>
              <div class="modal-footer">
                <PlButton
                  label="Fechar"
                  class="pl-btn"
                  cancel
                  gray
                  :disabled="disableButton"
                  @click="closeModalAcceptTerm"
                />
              </div>
            </div>

            <div
              class="modal"
              id="accept-term-modal"
              v-if="showModalQuotaInformation"
            >
              <div class="modal-header">
                <h2>Informações de Cotas</h2>
                <i
                  class="fas fa-times fa-2x btn-close"
                  @click="closeModalQuotaInformation()"
                ></i>
              </div>

              <div class="modal-dialog">
                <table v-show="hasGiftCardList">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Especialidade</th>
                      <th>Resgate</th>
                      <th>Vencimento</th>
                      <th>Saldo a resgatar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="th"
                      v-for="item in giftCardList"
                      v-bind:key="item.length"
                    >
                      <td class="ti">{{ item.gtc_codigo }}</td>
                      <td class="ti">{{ item.cbo_nome }}</td>
                      <td class="ti">{{ item.gtc_data_resgate }}</td>
                      <td class="ti">{{ item.gtc_data_expiracao_resgate }}</td>
                      <td class="ti">{{ item.gcq_quantidade_disponivel }}</td>
                    </tr>
                  </tbody>
                </table>

                <div v-show="!hasGiftCardList" class="empty">
                  <IconNotFoundDocuments :style="{ width: '180px' }" />
                  <span>Não foram encontrados registros de cotas.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="include-gift-card-container"></div>
      </section>
    </section>

    <section class="footer">
      <div>
        <PlButton label="" primary arrowLeft @click="onClickBack" />
      </div>
    </section>
  </section>
</template>

<script>
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";
import IconArrow from "@/assets/icons/IconArrow";
import IconClose from "@/assets/icons/IconClose";
import PlButton from "@/components/button/Button";
import TermMedicar from "@/components/term/TermMedicar";
import TermAxia from "@/components/term/TermAxia";
import IconNotFoundDocuments from "@/assets/icons/IconNotFoundDocuments";

import moment from "moment";

import PatientHeader from "../components/header/PatientHeader";

export default {
  name: "PatientGiftCard",
  components: {
    PatientHeader,
    IconArrow,
    IconClose,
    PlButton,
    TermMedicar,
    TermAxia,
    IconNotFoundDocuments,
  },

  data() {
    return {
      balance: null,
      showBalance: true,
      showModalAcceptTerm: false,
      showModalQuotaInformation: false,
      giftCard: null,
      rescuedGiftCard: null,
      cotes: [],
      giftCardList: [],
      termComponent:
        process.env.VUE_APP_THEME === "axia" ? TermAxia : TermMedicar,
    };
  },

  async mounted() {
    this.patient = await this.$store.dispatch("getLocalStoragePatient");
    this.servicoHttp = new ServicoHttp(this);
    this.sPepLinkGiftCard = new ServicoAll(
      "/api/pep/gift-card/link",
      this.servicoHttp
    );
    this.sPepCheckCurrentGiftCard = new ServicoAll(
      "/api/pep/gift-card/check-current-gift-card",
      this.servicoHttp
    );
    this.sPepSumGiftCardCotes = new ServicoAll(
      "/api/pep/gift-card/sum-gift-card-cotes",
      this.servicoHttp
    );
    this.sPepSumGiftCardQuotasQuantity = new ServicoAll(
      "/api/pep/gift-card/sum-gift-card-quotas-quantity",
      this.servicoHttp
    );

    this.getCurrentGiftCard();
    this.sumGiftCardCotes();
  },

  computed: {
    hasGiftCardList() {
      return this.giftCardList && this.giftCardList.length > 0;
    },
    termComponent() {
      return process.env.VUE_APP_THEME === "axia" ? "TermAxia" : "TermMedicar";
    },
  },

  methods: {
    onClickBack() {
      this.$router.push({ name: "pacienteHome" });
    },

    toggleBalance() {
      this.showBalance = !this.showBalance;
    },

    openModalAcceptTerm() {
      this.showModalAcceptTerm = true;
    },
    closeModalAcceptTerm() {
      this.showModalAcceptTerm = false;
    },
    async openModalQuotaInformation() {
      const giftCardList = await this.sPepSumGiftCardQuotasQuantity.post({
        pacId: this.patient.pac_id,
      });

      this.giftCardList = giftCardList.data.data;

      this.showModalQuotaInformation = true;
    },
    closeModalQuotaInformation() {
      this.showModalQuotaInformation = false;
    },

    async getCurrentGiftCard() {
      const ret = await this.sPepCheckCurrentGiftCard.post({
        idPac: this.patient.pac_id,
      });

      if (ret.data.status) {
        this.rescuedGiftCard = ret.data.data[0].gtc_codigo;
      }
    },
    async sumGiftCardCotes() {
      const ret = await this.sPepSumGiftCardCotes.post({
        idPac: this.patient.pac_id,
      });

      if (ret.data.status) {
        ret.data.data.forEach((item) => {
          item.formatedDate = moment(item.gic_data_criacao).format(
            "DD/MM/YYYY"
          );
        });

        this.cotes = ret.data.data;
      }
    },
    async sendGiftCard() {
      if (!this.giftCard) {
        this.$toast.info(
          "Código não informado! <br> Por favor, informe o número de utilização do seu cartão e tente novamente.",
          { duration: 3000 }
        );
        return;
      }

      try {
        const cleanCpf = this.patient.usu_cpf.replace(/\D/g, '')
        const ret = await this.sPepLinkGiftCard.post({
          cpf: cleanCpf,
          giftCard: this.giftCard
        })
        console.log(ret.data, 'ret.data.status');
        if (ret.data.status === true) {
          this.rescuedGiftCard = this.giftCard;

          this.sumGiftCardCotes();

          this.$toast.success(
            "Código resgatado! <br> Seu Gift Card foi resgatado com sucesso! Você já pode solicitar atendimento ou agendar uma consulta.",
            { duration: 3000 }
          );
        } else {
          this.$toast.info(
            "Código inválido ou expirado. <br> Por favor, verifique o número de utilização do seu cartão e tente novamente.",
            { duration: 3000 }
          );
        }

        this.giftCard = "";
      } catch (error) {
        if (error.response.data.message === "Gift card não encontrado.") {
          this.$toast.info(
            `Código inválido! <br> Por favor, verifique o número de utilização do seu cartão e tente novamente.`,
            { duration: 3000 }
          );
        }
        if (error.response.data.message === "Este gift card já foi usado.") {
          this.$toast.info(
            `Código já utilizado <br> Desculpe, mas este código já foi utilizado anteriormente. Você só pode utilizá-lo uma vez.`,
            { duration: 3000 }
          );
        }
      }
    },
  },
};
</script>
