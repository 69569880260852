import { render, staticRenderFns } from "./ForgotPsw.vue?vue&type=template&id=d5d60e2c&scoped=true"
import script from "./ForgotPsw.vue?vue&type=script&lang=js"
export * from "./ForgotPsw.vue?vue&type=script&lang=js"
import style0 from "./ForgotPsw.scss?vue&type=style&index=0&id=d5d60e2c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5d60e2c",
  null
  
)

export default component.exports